/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '@osrdata/app_core/dist/requests'
import { OfferType } from './types'

export const fetchOffers = createAsyncThunk<OfferType[]>('offers/fetchOffers', async () => {
  const response = await get('/src/assets/json/data.json/')
  return response.data
})

/* export const postOffer =  createAsyncThunk<OfferType[], ParamsType, BaseThunkAPI>(
    'post/offer',
    async (params:type, ThunkAPI) => {
        const response: OfferType[] = await ('/pda/add/offer/', {

        })
    }
) */

/* parmsType = {
    offerString: OfferString,

} */
