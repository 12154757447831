/* eslint-disable max-len */
import terms from 'common/terms'
import { defineLink } from 'components/utils'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ProgramType, ServiceType } from 'reducers/programs/types'

interface Props {
  program: ProgramType | ServiceType;
  index: number;
}

const ProgramItem = ({ program, index }: Props): ReactElement => {
  const descriptionLength = program?.description_short?.length

  const description = (descriptionLength > 300) ? `${program.description_short.substring(0, 300)} ...` : program.description_short
  return (
    <>
      {
        (index % 2 === 0) ? (
          <div className="program">
            <div className="description">
              <div className="left">
                <div className="title">
                  {program.name}
                </div>
                <div className="content">
                  <div>
                    {description}
                  </div>
                </div>
                <div className="button">
                  <Link className="item" to={defineLink(program.slug)}>
                    {terms.homepageText.knowMore}
                  </Link>
                </div>
              </div>
            </div>
            <div className="image img-right">
              <div>
                <img src={program.image} alt={program.name} />
              </div>
            </div>
          </div>
        ) : (
          <div className="program">
            <div className="image">
              <div className="image-container">
                <img className="item" src={program.image} alt={program.name} />
              </div>
            </div>
            <div className="description">
              <div className="right">
                <div className="title">
                  {program.name}
                </div>
                <div className="content">
                  {description}
                </div>
                <div className="button">
                  <Link className="item" to={defineLink(program.slug)}>
                    {terms.homepageText.knowMore}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default ProgramItem
