import terms from 'common/terms'
import { ReactElement } from 'react'
// import { Link } from 'react-router-dom'
import { BlueBtn } from 'themes/theme'
import ExitToappIcon from '@mui/icons-material/ExitToApp'
import { useSelector } from 'react-redux'
import { ApplicationProgramType } from 'reducers/programs/types'
import { RootState } from 'Store'
import { sendEmail } from 'utils/functions'

interface Props {
  application: ApplicationProgramType
}

const AppExcerpt = ({ application }: Props): ReactElement => {
  const { appPermissions } = useSelector((state: RootState) => state.user)
  // const { programs } = useSelector((state: RootState) => state.programs)

  const subject = `${terms.Mailing.access.title} ${application?.name || ''}`
  const body = `${terms.Mailing.access.body} ${application?.name || ''}.${terms.Mailing.bodyFooter}`

  const descriptionLenght = application.description_short.length
  const description = (descriptionLenght > 400)
    ? `${application.description_short.substring(0, 400)} ...`
    : application.description_short

  // const programLink = programs.filter(program => program.apps.find(app => app.slug === application?.slug))[0].slug

  return (
    <div className="appExcerpt">
      <>
        <div className="header">
          <img src={application.icon} alt="" />
          <div className="title">
            {application.name}
          </div>
        </div>
        <div className="description">
          <div className="content">
            {description}
          </div>
          {/* }
          <div>
            <Link className="know-more-link" to={`/${programLink}/applications/${application.name}/`}>
              {terms.homepageText.knowMore}
            </Link>
          </div>
          */}
        </div>
        <div className="tags">
          {application.tags.filter(item => item !== 'highlight').map(tag => (
            <div className="item" key={tag}>{tag}</div>
          ))}
        </div>
        <div className="separation" />
        <div className="app-tile-buttons">
          {appPermissions.includes(application.access_perm) ? (
            <a href={application.uri} target="_blank" rel="noreferrer">
              <BlueBtn variant="contained" className="app-tile-button-go">
                <ExitToappIcon />
                <span className="app-tile-button-text">{terms.apps.access}</span>
              </BlueBtn>
            </a>
          ) : (
            <BlueBtn variant="contained" className="app-tile-button-ask" onClick={() => sendEmail(subject, body)}>
              <span className="app-tile-button-text">{terms.apps.ask}</span>
            </BlueBtn>
          )}
        </div>
      </>
    </div>
  )
}

export default AppExcerpt
