export default {
  // eslint-disable-next-line max-len
  test: 'Dans sa quête d’un réseau haute performance, le groupe SNCF s’est lancé dans une politique de modernisation sans précédent, visant notamment à une meilleure optimisation du système ferroviaire. A cette fin, il est nécessaire de développer une culture de l’Asset Management, aujourd’hui encore peu pré Dans sa quête d’un réseau haute performance, le groupe SNCF s’est lancé dans une politique de modernisation sans précédent, visant notamment à une meilleure optimisation du système ferroviaire. A cette fin, il est nécessaire de développer une culture de l’Asset Management, aujourd’hui encore peu pré',
  Mailing: {
    email: 'ext.duy.nguyen@reseau.sncf.fr',
    bodyFooter: '\n\n En vous remerciant d\'avance.',
    access: {
      title: 'Demande d\'accès',
      body: 'Bonjour,\n\nJe souhaite accéder à l\'application: ',
    },
    contact: {
      title: 'Demande de contact',
      body: 'Bonjour,\n\nJe souhaite prendre contact avec vous.',
    },
  },
  addOfferForm: {
    title: 'Publier une offre d\'emploi ',
    offerName: 'Nom du Poste',
    workLocation: 'Lieu de travail',
    category: 'Catégorie',
    telework: 'Télétravail',
    workExperience: 'Expérience professionelle',
    formation: 'Formation',
    startDate: 'Date de démarrage',
    offerDescription: 'Description du poste',
    profile: 'Profil recherché',
    whoAreWe: 'Qui sommes-nous',
    cancel: 'Annuler',
    publish: 'Publier l\'annonce',
  },
  editOfferForm: {
    title: 'Editer une offre d\'emploi',
    publish: 'Editer l\'annonce',
  },
  offerExcerpt: {
    time: 'Il y a ',
    days: ' jours',
  },
  recrutementPage: {
    title: 'Rejoignez-nous!',
    subtitle: 'Consultez nos offres et postulez.',
    manageOffersButton: 'Gérer les offres d\'emploi',
    publishNewOffer: 'Publier une nouvelle offre',
    changesDone: 'Modifications terminées',
  },
  deleteModal: {
    title: 'Souhaitez-vous supprimer cette offre d\'emploi ?',
    cancel: 'Annuler',
    confirm: 'Confirmer',
  },
  singleOfferPage: {
    published: 'Publié le',
    backToList: 'Retour aux offres',
    description: 'Description du poste',
    profile: 'Profil Recherché',
    whoAreWe: 'Qui sommes-nous',
    notFound: 'Offre introuvable !',
    start: 'Démarrage :',
    postulate: 'Postuler à l\'offre',
  },
  workCategory: [
    {
      buttonText: 'Tech',
      value: 'Tech',
    },
    {
      buttonText: 'Produit',
      value: 'Produit',
    },
    {
      buttonText: 'Marketing / Communication',
      value: 'Marketing',
    },
    {
      buttonText: 'Administratif',
      value: 'Administratif',
    },
  ],
  navigation: {
    accueil: {
      path: '/accueil/',
      name: 'Accueil',
    },
    applications: {
      path: '/applications/',
      name: 'Nos applications',
    },
    programmes: {
      path: '/notre-organisation/',
      name: 'Notre organisation',
    },
    recrutement: {
      path: '/recrutement/',
      name: 'Recrutement',
    },
    resilience: {
      path: '/resilience-exploitation/',
      name: 'Résilience de l\'exploitation',
    },
    reseau: {
      path: '/usage-du-reseau/',
      name: 'Usage du réseau',
    },
    transformation: {
      path: '/transformation-numerique/',
      name: 'Transformation Numérique',
    },
  },
  teleWorkSelect: {
    noTelework: 'Pas de télétravail',
    partialTelework: 'Télétravail Partiel',
    authorizedTelework: 'Télétravail autorisé',
  },
  experienceSelect: {
    lessThan1Year: 'Moins d\'1 an',
    between1And3Years: '1 à 3 ans d\'expérience',
    between3and5Years: '3 à 5 ans d\'expérience',
    moreThan5Years: '+ de 5 ans d\'expérience',
  },
  homepageText: {
    title: 'Pôle Données et Innovation -',
    solution: 'DGEX Solutions',
    subTitle: 'Portail des applications ferroviaires',
    presentationText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    programs: 'Nos programmes',
    apps: 'Notre sélection d’applications',
    services: 'Nos services',
    transformation: 'Transformation Numérique',
    carouselTitle: 'Actualités',
    knowMore: 'En savoir plus',
    seeAll: 'Voir tout',
    programDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, 
    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    processTitle: 'Processus, Architecture & Normes',
    processDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, 
    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    dataAndServices: 'Plateforme Données & Services',
    interfaceAndApplication: 'Service Interface & Application',
    infrastructure: 'Service Infrastructure',
    footerText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
    ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
    copyright: '© SNCF Réseau 2021',
    contactUs: 'Nous contacter',
  },
  popupnew: 'Le forum de la DGEX se tiendra le 21 et 22 octobre 2023, sur le campus Rimbaud.',
  apps: {
    access: 'Accéder à l\'application',
    ask: 'Demander l\'accès',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing 
    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    myapps: 'Mes applications',
    noDocumentation: 'This app has no documentation',
  },
  organisation: {
    title: 'Nos programmes et services',
  },
} as const
