import { ReactElement } from 'react'
import { NavElement } from 'utils/navRoutes'
import { useMediaQuery } from 'react-responsive'
import TabElement from './TabElement'

type Props = {
  tabs: NavElement[]
}

const style = {
  flex: 1,
}

export default function TabList({ tabs }: Props): ReactElement {
  const isBurgerMenu = useMediaQuery({
    query: '(max-width: 1370px)',
  })

  return (
    <>
      {
        isBurgerMenu ? (
          <nav className="d-flex align-item-center">
            <ul className="d-flex align-item-center ul-tab-mobile">
              <li className="d-flex align-item-center flex-column list-tab-element" style={style}>
                {tabs.map(el => (
                  <TabElement key={el.path} element={el} />
                ))}
              </li>
            </ul>
          </nav>
        ) : (
          <nav className="d-flex align-item-center h-100 ml-auto mr-5 pr-5">
            <ul className="d-flex align-item-center h-100 pl-0">
              <li className="d-flex align-item-center h-100" style={style}>
                {tabs.map(el => (
                  <TabElement key={el.path} element={el} />
                ))}
              </li>
            </ul>
          </nav>
        )
      }
    </>
  )
}
