import { ReactElement } from 'react'
import terms from 'common/terms'
import logo1 from 'assets/images/PDI_LOGO.png'
import logo2 from 'assets/images/logo2.png'
import logo3 from 'assets/images/logo3.png'
import './HomePageFooter.scss'
import { DefaultBtn } from 'themes/theme'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Skeleton } from '@mui/material'
import parse from 'html-react-parser'

interface HomePageFooterProps {
  data: string
}

export default function HomePageFooter({ data }: HomePageFooterProps): ReactElement {
  const logos = [logo1, logo2, logo3]
  const { loading, homepageContent } = useSelector((state: RootState) => state.programs)

  const handleContact = () => {
    const emailAdress = terms.Mailing.email
    const subject = terms.Mailing.contact.title
    const body = `${terms.Mailing.contact.body} ${terms.Mailing.bodyFooter}`

    const mailtoLink = `mailto:${emailAdress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.location.href = mailtoLink
  }

  return (
    <div className="homepage-footer">
      <div className="homepage-footer-content">
        <div className="logo-line">
          {
            loading ? (
              <Skeleton variant="rounded" height={24} className="footer-logos-skeleton" />
            ) : (
              logos.map(logo => (
                <img key={logo} src={logo} alt="" />
              ))
            )
          }
        </div>
        {
          loading ? (
            <Skeleton variant="rounded" height={74} className="footer-text-skeleton" />
          ) : (
            <div className="footer-text">
              <p>{ parse(homepageContent.descriptionLong)}</p>
              <p>{data}</p>
            </div>
          )
        }
        <DefaultBtn onClick={handleContact}>
          {terms.homepageText.contactUs}
        </DefaultBtn>
      </div>
    </div>
  )
}
