import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'
import { toggleLoading } from 'reducers/programs/programs.reducer'
import {
  OfferState,
  OfferDateType,
  offerIdType,
  OfferImportType,
  OfferStringType,
  OfferType,
} from './types'
import { fetchOffers } from './offer.thunk'

const initialState: OfferState = {
  offers: [],
  status: 'idle',
  isError: false,
  offer: {
    id: '',
    category: '',
    content: '',
    profile: '',
    name: '',
    location: '',
    telework: '',
    requirement: '',
    experience: '',
    whoAreWe: '',
  },
  offerDate: {
    date: new Date(),
    startDate: new Date(),
  },
}

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    offerAdded(state) {
      const stateObject : OfferType = {
        id: nanoid(),
        category: state.offer.category,
        content: state.offer.content,
        profile: state.offer.profile,
        name: state.offer.name,
        location: state.offer.location,
        telework: state.offer.telework,
        requirement: state.offer.requirement,
        experience: state.offer.experience,
        whoAreWe: state.offer.whoAreWe,
        date: state.offerDate.date,
        startDate: state.offerDate.startDate,
      }
      state.offers.push(stateObject)
    },
    offerUpdated(state) {
      const existingOffer = state.offers.find(offer => offer.id === state.offer.id)
      if (existingOffer) {
        existingOffer.id = state.offer.id
        existingOffer.category = state.offer.category
        existingOffer.content = state.offer.content
        existingOffer.profile = state.offer.profile
        existingOffer.name = state.offer.name
        existingOffer.location = state.offer.location
        existingOffer.telework = state.offer.telework
        existingOffer.startDate = state.offerDate.startDate
        existingOffer.requirement = state.offer.requirement
        existingOffer.experience = state.offer.experience
        existingOffer.whoAreWe = state.offer.whoAreWe
      }
    },
    offerDeleted(state, action: PayloadAction<offerIdType>) {
      const { offerId } = action.payload
      const existingOffer = state.offers.find(offer => offer.id === offerId)
      if (existingOffer) {
        state.offers = state.offers.filter(offer => offer.id !== existingOffer.id)
      }
    },
    handleOfferChange(state, action: PayloadAction<{
      value: string,
      name: keyof OfferStringType
    }>) {
      state.offer[action.payload.name] = action.payload.value
    },
    handleOfferDateChange(state, action: PayloadAction<{
      value: Date,
      name: keyof OfferDateType
    }>) {
      state.offerDate[action.payload.name] = action.payload.value
    },
    reinitializeStates(state) {
      state.offer = initialState.offer
      state.offerDate = initialState.offerDate
    },
    dataAdded(state, action: PayloadAction<OfferImportType[]>) {
      const offerImport: OfferType[] = []
      action.payload.map(offer => (
        offerImport.push({
          id: offer.id,
          category: offer.category,
          profile: offer.profile,
          content: offer.content,
          name: offer.name,
          location: offer.location,
          date: new Date(offer.date),
          startDate: new Date(offer.startDate),
          telework: offer.telework,
          whoAreWe: offer.whoAreWe,
          requirement: offer.requirement,
          experience: offer.experience,
        })
      ))
      state.offers = offerImport
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOffers.pending, () => {
        toggleLoading(true)
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.offers = action.payload
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.error = action.error.message
        state.isError = true
      })
  },
})

export default offersSlice.reducer

export const {
  offerAdded, offerUpdated, offerDeleted, handleOfferDateChange, handleOfferChange, reinitializeStates,
  dataAdded,
} = offersSlice.actions
