import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { v4 as uuidv4 } from 'uuid'
import headerImage from 'assets/images/homepageBackground.png'
import terms from 'common/terms'
import ProgramItem from './ProgramItem'
import './AllProgram.scss'

export default function AllProgram(): ReactElement {
  const { programs, services } = useSelector((state: RootState) => state.programs)
  const organisation = [...programs, ...services]
  return (
    <div className="programs">
      <div className="header">
        <div className="title">
          <div>
            {terms.organisation.title}
          </div>
        </div>
        <img src={headerImage} alt="header" />
        <div className="img-overlay" />
      </div>
      {
        organisation.map((program, index) => (
          <ProgramItem
            key={uuidv4()}
            program={program}
            index={index}
          />
        ))
      }
    </div>
  )
}
