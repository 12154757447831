import {
  ReactElement, ReactNode, useState,
} from 'react'
import { Dialog, DialogContent } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'
import logo1 from 'assets/images/PDI_LOGO_BLANC.png'
import { useMediaQuery } from 'react-responsive'
import AccountMenu from './AccountMenu'
import './topBar.scss'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ appName, innerComponent, hasAccess }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState(false)

  const handleClick = () => {
    setAnchorEl(!anchorEl)
  }

  const isBurgerMenu = useMediaQuery({
    query: '(max-width: 1370px)',
  })

  return (
    <div className="topBar d-flex align-items-center justify-content-between px-3">
      <header role="banner" className="d-xl-flex d-flex">
        <div className="mastheader-logo">
          <a href="/"><img alt={appName} src={logo} width="70" /></a>
          <img src={logo1} key="" alt="logopdi" className="logo-pdi" width="70" />
        </div>
      </header>
      <>
        {
          isBurgerMenu ? (
            <div className="topBar-dialog d-flex justify-content-end">
              <MenuIcon onClick={handleClick} className="justify-content-end topBar-icon-white" />
              <Dialog open={anchorEl} fullWidth maxWidth="sm" onClose={handleClick}>
                <DialogContent className="topBar-dialog-content">
                  <AccountMenu />
                  <button type="button" className="topBar-menu-button" onClick={handleClick}>
                    {hasAccess && innerComponent}
                  </button>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            <>
              {hasAccess && innerComponent}
              <AccountMenu />
            </>
          )
        }
      </>
    </div>
  )
}

TopBar.defaultProps = defaultProps
