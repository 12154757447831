import { ReactElement } from 'react'
import backgroundImage from 'assets/images/homepageBackground.png'
import './HomeBar.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import parse from 'html-react-parser'

export default function HomeBar() : ReactElement {
  const { descriptionLong, descriptionShort, title } = useSelector((state: RootState) => state.programs.homepageContent)

  return (
    <div className="home-bar" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
      <div className="home-bar-wrap">
        <div className="home-bar-text">
          <div className="home-bar-title">
            <div className="home-bar-title-pole">{title}</div>
          </div>
          <div className="home-bar-subtitle">{descriptionShort}</div>
          <div className="home-bar-description">{ parse(descriptionLong) }</div>
        </div>
        <div className="shadow-text" />
      </div>
    </div>
  )
}
