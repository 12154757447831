import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import nextId from 'react-id-generator'
import { Skeleton } from '@mui/material'
import terms from 'common/terms'
import './HomeApps.scss'
import { Link } from 'react-router-dom'
import AppExcerpt from './AppExcerpt'

export default function HomeApps(): ReactElement {
  const { programs } = useSelector((state: RootState) => state.programs)
  const { loading } = useSelector((state:RootState) => state.programs)

  return (
    <div className="home-apps">
      <div className="different-programs">
        <div>
          {terms.homepageText.apps}
        </div>
        <Link className="link" to={terms.navigation.applications.path}>
          {terms.homepageText.seeAll}
        </Link>
      </div>
      <div className="home-apps-tilelist">
        {
          loading ? (
            <>
              <Skeleton variant="rounded" height={430} className="skeletonPrograms" />
              <Skeleton variant="rounded" height={430} className="skeletonPrograms" />
              <Skeleton variant="rounded" height={430} className="skeletonPrograms" />
            </>
          ) : (
            programs.flatMap(obj => obj.apps).filter(objs => objs?.tags.includes('highlight')).map(app => (
              <AppExcerpt application={app} key={nextId()} />
            ))
          )
        }
      </div>
    </div>
  )
}
