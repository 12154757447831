import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ProgramType } from 'reducers/programs/types'
import terms from 'common/terms'
import { defineLink } from 'components/utils'

interface programExcerptProps {
  actualProgram: ProgramType
}

export default function ProgramExcerpt({ actualProgram }: programExcerptProps): ReactElement {
  const descriptionLength = actualProgram.description_long.length
  const description = (descriptionLength > 200)
    ? `${actualProgram.description_long.slice(0, 200)} ...`
    : actualProgram.description_long
  return (
    <>
      <div className="program-excerpt">
        <img className="excerpt-image" src={actualProgram.image} alt="trainimage1" />
        <div className="program-description">
          <div className="program-title">{actualProgram.name}</div>
          <div className="program-short">
            {description}
          </div>
          <Link className="know-more-link" to={defineLink(actualProgram.slug)}>
            {terms.homepageText.knowMore}
          </Link>
        </div>
      </div>
    </>
  )
}
