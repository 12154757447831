import { ReactElement } from 'react'
import './Home.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import HomeBar from './HomeBar/HomeBar'
import PopUpNews from './PopUpNews/PopUpNews'
import HomePrograms from './HomePrograms/HomePrograms'
import HomePageFooter from './HomePageFooter/HomePageFooter'
import HomeApps from './HomeApps/HomeApps'

export default function Home(): ReactElement {
  const { news, homepageContent } = useSelector((state: RootState) => state.programs)

  return (
    <div className="home">
      <HomeBar />
      { news.length > 0
          && <PopUpNews data={news} />}
      <HomeApps />
      <HomePrograms />
      {/* <PoleServices data={services} /> */}
      <HomePageFooter data={homepageContent.footerContent} />
    </div>
  )
}
