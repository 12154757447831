import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '@osrdata/app_core/dist/requests'
import { ImportType } from './types'

const fetchData = createAsyncThunk<ImportType>('programs/fetchData', async (_, thunkApi) => {
  try {
    const response = await get('/olympe/business-units/')
    return {
      programs: response[0].app_groups,
      news: response[0].news,
      services: response[0].services,
      homepageContent: {
        footerContent: response[0].footer_content,
        title: response[0].name,
        descriptionLong: response[0].description_long,
        descriptionShort: response[0].description_short,
      },
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e:any) {
    return thunkApi.rejectWithValue({
      message: e.response.data,
    })
  }
})

export default fetchData
