import terms from 'common/terms'
import {
  ReactElement,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { RootState } from 'Store'
import { setAccessFilter, setProgramFilter } from 'reducers/programs/programs.reducer'

export default function AppFilters(): ReactElement {
  const dispatch = useDispatch()
  const programs = useSelector((state: RootState) => state.programs.programs)
  const filters = useSelector((state: RootState) => state.programs.filters)

  return (
    <>
      <Button
        key={terms.apps.myapps}
        className={filters.currentAccess ? 'activeFilterBtn' : ''}
        onClick={() => dispatch(setAccessFilter())}
      >
        {terms.apps.myapps}
      </Button>
      {
        programs.map(program => (
          <Button
            key={program.name}
            className={filters.program === program.name ? 'activeFilterBtn' : ''}
            onClick={() => dispatch(setProgramFilter(program.name))}
          >
            {program.name}
          </Button>
        ))
      }
    </>
  )
}
