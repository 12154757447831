import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // localStorage
import { rootReducer } from 'reducers'
import { initialState as programInit } from 'reducers/programs/programs.reducer'

// Configuration de la persistance pour le reducer 'user' (localStorage)
const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['account'], // Les clés à conserver dans localStorage
}

// Configuration de la persistance pour le reducer 'programs' (sessionStorage)
const programsPersistConfig = {
  key: 'programs',
  storage,
  whitelist: Object.keys(programInit), // Les clés à conserver dans sessionStorage
}

const persistedUserReducer = persistReducer(userPersistConfig, rootReducer.user)
const persistedProgramsReducer = persistReducer(programsPersistConfig, rootReducer.programs)

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    app: rootReducer.app,
    offers: rootReducer.offers,
    programs: persistedProgramsReducer,
  },
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
