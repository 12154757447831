import { ReactElement, useState } from 'react'
import terms from 'common/terms'
import { useTheme } from '@mui/material/styles'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import './PopUpNews.scss'
import { DefaultBtn } from 'themes/theme'
import { MobileStepper, Button } from '@mui/material'
import { Box } from '@mui/system'

interface PopUpNewsProps {
  data: {
    title: string,
    content: string,
    link: string,
    image: string
  }[]
}

export default function PopUpNews({ data }: PopUpNewsProps): ReactElement {
  const theme = useTheme()

  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = data.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }
  const handleContact = () => (
    window.open(data[0].link)
  )

  return (
    <div className="home-popup-news">
      <div className="popup-news-carousel">
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {data.map((step, index) => (
            <div key={step.title}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div style={{ display: 'flex' }}>
                  <Box
                    sx={{
                      height: 482,
                      display: 'block',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    <div className="left-side">
                      <div className="left-side-title">{terms.homepageText.carouselTitle}</div>
                      <div className="left-side-subtitle">{step.title}</div>
                      <div className="left-side-description">{step.content}</div>
                      <DefaultBtn onClick={handleContact}>
                        {terms.homepageText.knowMore}
                      </DefaultBtn>
                    </div>
                  </Box>

                  <Box
                    component="img"
                    className="right-side"
                    sx={{
                      height: 482,
                      display: 'block',
                      overflow: 'hidden',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    src={step.image}
                    alt={step.title}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </SwipeableViews>
        <MobileStepper
          sx={{
            background: 'white',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            bottom: 0,
            padding: 0,
            borderRadius: 16,
          }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={(
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
        )}
          backButton={(
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
        )}
        />
      </div>
    </div>
  )
}
