import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ApplicationProgramType, ProgramState, ProgramType,
} from './types'
import fetchData from './programs.thunk'

export const initialState: ProgramState = {
  programs: [],
  filters: {
    program: '',
    currentAccess: false,
  },
  applications: [],
  news: [],
  services: [],
  homepageContent: {
    footerContent: '',
    title: '',
    descriptionShort: '',
    descriptionLong: '',
  },
  isError: false,
  loading: false,
}

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    applicationFavorited: (state, action: PayloadAction<ApplicationProgramType>) => {
      const program = state.programs.find(
        (prog: ProgramType) => prog.apps.find((app: ApplicationProgramType) => app.name === action.payload.name),
      )
      const application = program?.apps.find(
        (apps: ApplicationProgramType) => apps.name === action.payload.name,
      )
      if (application) {
        application.favorite = !application.favorite
      }
    },
    setProgramFilter: (state, action: PayloadAction<string>) => {
      if (action.payload === state.filters.program) {
        state.filters.program = ''
      } else {
        state.filters.program = action.payload
      }
    },
    setAccessFilter: state => {
      state.filters.currentAccess = !state.filters.currentAccess
    },
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchData.pending, state => {
        state.loading = true
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.programs = action.payload.programs
        state.news = action.payload.news
        const services = action.payload.services.map(service => ({
          ...service,
          apps: [],
        }))
        state.services = services
        state.homepageContent = action.payload.homepageContent
        state.loading = false
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.error = action.error.message
        state.isError = true
      })
  },
})

export default programsSlice.reducer
export const {
  applicationFavorited,
  setProgramFilter,
  setAccessFilter,
  toggleLoading,
} = programsSlice.actions
