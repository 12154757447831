import { MouseEvent, ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'Store'
import { NavElement } from 'utils/navRoutes'

import './tabElement.scss'
import {
  ClickAwayListener,
  MenuList, Paper, Popper,
} from '@mui/material'
import { ApplicationProgramType, ProgramType } from 'reducers/programs/types'
import { useMediaQuery } from 'react-responsive'

type Props = {
  element: NavElement;
}

export default function TabElement({ element }: Props): ReactElement {
  const {
    title,
    path,
    role,
  } = element

  const location = useLocation()
  const isBurgerMenu = useMediaQuery({
    query: '(max-width: 1370px)',
  })

  const isSelected = location.pathname.includes(path)

  const { appPermissions } = useSelector((state: RootState) => state.user)
  const apps = useSelector((state: RootState) => state.programs).programs.find(
    (p: ProgramType) => p.name === title,
  )?.apps as ApplicationProgramType[]
  const [menuAnchor, setMenuAnchor] = useState<null | Element>(null)
  const [isHovering, setIsHovering] = useState(false)
  const [menuWidth, setMenuWidth] = useState(0)

  const isAllowed = role.length === 0
  || ((appPermissions as string[]).some(permission => role.includes(permission)))

  const handleMouseOver = (event:MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget)
    setMenuWidth(event.currentTarget.clientWidth)
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  return (
    <>
      {isAllowed && title
      && (
        <Link to={path}>
          <div
            className={`tab-element d-flex justify-content-center align-items-center 
            h-100 px-3 ${isSelected ? 'selected' : ''}`}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onFocus={() => undefined}
            onBlur={() => undefined}
          >
            <div className="title" onBlur={() => undefined}>
              {title}
              {(apps) && (!isBurgerMenu) && apps.length > 0 && (
                <Popper
                  className="program-menu"
                  anchorEl={menuAnchor}
                  open={isHovering}
                  style={{ minWidth: menuWidth }}
                >
                  <Paper className="app-menu-background">
                    <ClickAwayListener onClickAway={handleMouseOut}>
                      <MenuList
                        autoFocusItem={isHovering}
                        id="composition-menu"
                        className="app-menu"
                        aria-labelledby="composition-button"
                      >
                        {apps?.map(currentApp => (
                          <Link
                            to={`${path}applications/${currentApp.name}/`}
                            key={currentApp.name}
                          >
                            <div className="app-link">
                              {currentApp.name}
                            </div>
                          </Link>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              )}
            </div>
          </div>
        </Link>
      )}
    </>
  )
}
