import { ProgramType, ServiceType } from 'reducers/programs/types'

const bgColorProg = (program: ProgramType | ServiceType | undefined): string => {
  switch (program?.slug) {
    case 'transformation-numerique':
      return '#D3C8F7'
    case 'usage-du-reseau':
      return '#C8F1F7'
    case 'resilience-de-l-exploitation':
      return '#C8DBF7'
    case 'Fret':
      return '#ebc8f7'
    case 'labinnovation':
      return '#c8f7d3'
    default:
      return '#d3c8f7'
  }
}

export default bgColorProg
