import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import appReducer from './app'
import offersReducer from './offers/offers.reducer'
import programsReducer from './programs/programs.reducer'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
  'programs',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user', 'programs'],
}

export const rootReducer = {
  user: userReducer,
  app: appReducer,
  offers: offersReducer,
  programs: programsReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
