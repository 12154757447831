import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import { Link } from 'react-router-dom'
import terms from 'common/terms'
import ProgramExcerpt from './ProgramExcerpt/ProgramExcerpt'
import './HomePrograms.scss'

export default function HomePrograms(): ReactElement {
  const { programs } = useSelector((state: RootState) => state.programs)
  const { loading } = useSelector((state:RootState) => state.programs)

  return (
    <div className="home-programs">
      <div className="different-programs">
        <div>
          {terms.homepageText.programs}
        </div>
        <Link className="link" to={terms.navigation.programmes.path}>
          {terms.homepageText.seeAll}
        </Link>
      </div>
      <div className="program-list">
        {
          loading ? (
            <>
              <Skeleton variant="rounded" height={430} className="skeletonPrograms" />
              <Skeleton variant="rounded" height={430} className="skeletonPrograms" />
              <Skeleton variant="rounded" height={430} className="skeletonPrograms" />
            </>
          ) : (
            programs.slice(0, 4).map(actualProgram => (
              <ProgramExcerpt key={actualProgram.name} actualProgram={actualProgram} />
            ))
          )
        }
      </div>
    </div>
  )
}
