import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationProgramType, ProgramType } from 'reducers/programs/types'
import ExitToappIcon from '@mui/icons-material/ExitToApp'
import './AppsPage.scss'
import { BlueBtn } from 'themes/theme'
import terms from 'common/terms'
import { useParams } from 'react-router-dom'
import parser from 'html-react-parser'

interface AppsPageParams {
  applicationName: string
}

export default function AppsPage(): ReactElement {
  const { programs } = useSelector((state: RootState) => state.programs)
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const { applicationName }: AppsPageParams = useParams()

  const createAppArray = () => programs.flatMap((program: ProgramType) => (program.apps))
  const app = createAppArray().find(
    (apps: ApplicationProgramType) => apps.name === applicationName,
  ) as ApplicationProgramType

  const handleEmailClick = () => {
    const emailAdress = 'ext.duy.nguyen@reseau.sncf.fr'
    const subject = `Demande d'accés à ${app.name}`
    const body = `Bonjour, je souhaite accéder à l'application ${app.name}.\n\nMerci d'avance.`

    const mailtoLink = `mailto:${emailAdress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.location.href = mailtoLink
  }

  return (
    <div className="app-page">
      <div className="app-page-banner">
        <div className="app-page-banner-left-section">
          <div className="app-page-banner-text">
            <img src={app?.logo} alt="" className="app-page-title-logo" />
            <p>{app?.description_short}</p>
            <div className="button-container">
              {
                appPermissions.includes(`${app.slug}::ACCESS`) ? (

                  <BlueBtn variant="contained" className="app-page-button-go">
                    <a href={app.uri} target="_blank" rel="noreferrer" className="link">
                      <ExitToappIcon />
                      <span className="app-page-button-text">{terms.apps.access}</span>
                    </a>
                  </BlueBtn>

                ) : (
                  <BlueBtn variant="contained" className="app-page-button-ask" onClick={handleEmailClick}>
                    <span className="app-page-button-text">{terms.apps.ask}</span>
                  </BlueBtn>
                )
              }
            </div>

          </div>
        </div>
        <div className="app-page-banner-right-section">
          <div className="app-page-banner-image">
            <img src={app?.image} alt="app-screen" className="app-image" />
          </div>
        </div>
      </div>
      <div className="app-description">
        { parser(app.description_long) }
      </div>
    </div>
  )
}
