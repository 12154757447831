import { Button, styled } from '@mui/material'

export const DefaultBtn = styled(Button)({
  borderRadius: '64px',
  backgroundColor: 'inherit',
  border: '1px solid #030E18',
  boxShadow: 'none',
  color: 'black',
  textTransform: 'none',
})

export const BlueBtn = styled(Button)({
  borderRadius: '64px',
  background: '#456EFA',
  boxShadow: 'none',
  color: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  textTransform: 'none',
})
