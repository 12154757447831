import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ProgramType } from 'reducers/programs/types'
import { useLocation } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { defineLink } from 'components/utils'
import parse from 'html-react-parser'
import AppExcerpt from 'pages/Home/HomeApps/AppExcerpt'
import bgColorProg from './utils'
import './ProgramPage.scss'

export default function ProgramPage(): ReactElement {
  const {
    programs, services, loading,
  } = useSelector((state: RootState) => state.programs)

  const program = [...programs, ...services].find(
    (p: ProgramType) => useLocation().pathname === defineLink(p.slug),
  ) as ProgramType

  return (
    <div className="program-page">
      <div className="program-page-top-bar" style={{ background: bgColorProg(program) }}>
        <div className="program-page-top-bar-left">
          {
            loading ? (
              <Skeleton variant="rounded" className="program-page-top-bar-text" height={160} />
            ) : (
              <div className="program-page-top-bar-text">
                <div className="program-page-top-bar-name">
                  {program.name}
                </div>
                <div className="program-page-top-bar-description">
                  { parse(program.description_long) }
                </div>
              </div>
            )
          }
        </div>
        <div className="program-page-top-bar-image">
          {
            loading ? (
              <Skeleton variant="rectangular" style={{ height: '100%', width: '100%' }} />
            ) : (
              <img src={program.image} alt="program" />
            )
          }
        </div>
      </div>
      {
        program?.apps && (
          <div className="all-app-tilelist">
            {
          loading ? (
            <>
              <Skeleton variant="rounded" height={359} className="skeleton-tile" />
              <Skeleton variant="rounded" height={359} className="skeleton-tile" />
              <Skeleton variant="rounded" height={359} className="skeleton-tile" />
            </>
          ) : (
            program.apps.map(application => (
              <AppExcerpt application={application} key={application.name} />
            ))
          )
        }
          </div>
        )
      }

    </div>
  )
}
